.Homepage{
    min-height: 100vh;
    margin-top: 8vh;
}

.category-item{
    text-transform: uppercase;
}

.category-item span{
    margin: 0;
}

.category-span{
    font-size: .9rem;
    color: #2057a5;
    opacity: .6;
    position: relative;
    z-index: -1;
}

.category-span:not(:first-child){
    padding-right: 1rem !important;
}

.BlogSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.BlogSection section{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    max-width: 64rem;
}

@media only screen and (max-width: 1140px) {
    .BlogSection section{
        max-width: 80vw;
        align-items: center;
        justify-content: center;
    }
}

.BlogSection h2{
    font-family: "Fabrikant Medium", serif !important;
    font-size: 2rem;
    margin: .5rem 0;
    color: #2057a5;
}

.BlogItem{
    margin: 3rem 1rem;
    max-width: 30rem;
}

.BlogItem *{
    text-decoration: none;
}

.BlogItem Link span{
    padding: 1rem 1rem 1rem 0;
    cursor: pointer;
    margin: 0 !important;
    color: #2057a5;
}

.BlogItem .nav-link{
    margin: 1rem 0 !important;
    color: #2057a5;
}

.BlogItem img{
    width: 100%;
}

.BlogLink{
    cursor: pointer;
}

.perex{
    font-size: 1.2rem;
    margin: 0;
}

.read-more{
    font-size: 1rem;
    color: #2057a5;
    cursor: pointer;
    position: relative;
    z-index: -1;
}

.category-selector > div{
    margin-top: 1rem;
}

.category-selector > div:first-child{
    display: none;
}

.category-selector{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    text-transform: lowercase;
    flex-wrap: wrap;
    max-width: 80%;
}

.category-selector label{
    padding: .5rem 1rem;
    cursor: pointer;
}

.category-selector input{
    cursor: pointer;
}

.category-selector input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    opacity: 0;
}

.category-selector label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
}

.category-selector label:before {
    content: '';
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translate(-2%, -50%);
    width: 10px;
    height: 10px;
    border: 1px solid rgba(32, 87, 165, 0.6);
    transition: background-color 0.2s;
    z-index: -1;
    /*box-shadow: inset 0px 0px 0px 1px #F5F5F5 !important;*/
}

.category-selector input[type=checkbox]:checked + label:before {
    background-color: #2057a5 !important;
/*    box-shadow: inset 0px 0px 0px 1px #F5F5F5 !important;*/
}

.search-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
}

.search-container input{
    padding: .3rem .5rem;
    border: 1px solid rgba(32, 87, 165, 0.6);
    color: #2057a5;
    background-color: whitesmoke;
    margin: 0 1rem;
}

.search-container button{
    margin: 0;
    cursor: pointer;
}

.search-container input:focus-visible{
    outline: none !important;
}

@media only screen and (max-width: 930px) {
    .BlogSection section{
        max-width: 100%;
        align-items: center;
        justify-content: center;
    }

    .category-selector {
        max-width: 100%;
    }

    .search-container input {
        width: 50vw;
    }

    .BlogItem {
        margin: 3rem 0;
    }
}
