#navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 50px;
    transition: all 0.5s;
    z-index: 9999;
 /*   max-width: 1280px;
    margin: auto;*/
}

.nav-link {
    display: inline-flex;
    text-decoration: none;
    color: black;
    margin-left: 20px;
    line-height: 20px;
    position: relative;
    transition: transform .6s cubic-bezier(.25,.74,.22,.99);
    overflow: hidden;
}

.nav-link span{
    display: inline-block;
    white-space: nowrap;
    transition: transform .6s cubic-bezier(.25,.74,.22,.99);
}

.nav-link:after {
    content: attr(data-link);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,calc(-50% + 25px));
    white-space: nowrap;
    transition: transform .6s cubic-bezier(.25,.74,.22,.99);
}

.nav-link:hover span {
    transform: translateY(-25px);
}

.nav-link:hover:after {
    transform: translate(-50%,calc(-50% + 0px));
}

.light-navbar *{
    color: #F5F5F5FF !important;
}

#logo,
#mockup{
/*    height: 100px;*/
    transition: all 0.3s;
    height: 55px !important;
}

.shrinked-logo {
    height: 50px !important;
}

li {
    list-style: none;
}

#navbar p span{
    line-height: 20px;
    font-family: "Fabrikant Medium", serif !important;
}

.nav-container ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

.nav-container .logo{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
}

.logo2,
.mobile-navbar,
.overlay-menu,
.overlay{
    display: none;
}

@media only screen and (min-width: 1920px) {
    #navbar {
           max-width: 1280px;
           margin: auto;
    }
}
