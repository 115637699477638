.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
}

.container p{
    margin: 20px;
    font-family: "Fabrikant Medium", serif !important;
}

#mobile-navbar {
        display: block;
        transition: all 0.5s;
        z-index: 9999;
    }

    button {
        background: none;
        border: none;
        margin: 20px;
    }

    button:focus {
        outline: none;
    }

    .contain {
        position:relative;
        display: inline-block;
        cursor: pointer;
        z-index: 99999;
    }

    .change .container {
        transform: rotate(90deg);
    }

    .brand {
        color: #fff;
    }

    .bar1,
    .bar2,
    .bar3 {
        display: block;
        width: 25px;
        height: 2px;
        background-color: #111;
        border-radius: 2px;
        margin: 4px 0;
        transition: 0.4s;
    }

    .light-navbar .bar1,
    .light-navbar .bar2,
    .light-navbar .bar3,
    .change .bar1,
    .change .bar2,
    .change .bar3{
        background-color: #F5F5F5FF;
    }



    .change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-6px, 6.5px);
        transform: rotate(-45deg) translate(-5.5px, 4.5px);
        top: 50px;
    }

    .change .bar2 {
        position: relative;
        -webkit-transform: translate(30px, 0px);
        transform: translate(30px, 0px);
        opacity: 0;
    }

    .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-7px, -8px);
        transform: rotate(45deg) translate(-4px, -3px);
    }


    .mobile-navbar {
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 58px;
        display: none;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding: 0 40px;
        background-color: #222;
    }

    button:focus {
        outline: 0;
    }

    .open-menu {
        cursor: pointer;
        transition: transform 0.5s;
        color: #fff;
    }

    .button:hover,
    .open-menu:hover {
        transform: scale(1.1);
    }

    .logo2,
    .mobile-navbar,
    .overlay-menu,
    .overlay {
        display: block;
    }

    #logo2{
        height: 100px;
    }

    /* Overlay */

    .overlay-menu{
        height: 100%;
        padding: 3rem;
        max-height: 100vh;
    }

    .overlay-menu ul{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        max-height: 70vh;
        padding: 0 !important;
    }

    .overlay {
        position: fixed;
        z-index: 999;
        background: rgba(0, 0, 0);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.35s, visibility 0.35s, height 0.35s;
        overflow: auto;
    }

    .overlay.open {
        opacity: .9;
        visibility: visible;
        height: 100%;
        filter: grayscale(100%);
        overflow-y: hidden;
    }

    .overlay nav {
        position: relative;
        height: 300px;
        top: 300px;
        transform: translateY(-50%);
        text-align: center;
    }

    .overlay ul li {
        vertical-align: middle;
        display: block;
        position: relative;
        z-index: 9999;
        opacity: 0;
        margin-bottom: 35px;
    }

    .overlay ul li a {
        display: block;
        position: relative;
        z-index: 9999;
        color: #fff;
        text-decoration: none;
        overflow: hidden;
        transition: all 0.4s;

        font-size: 1.5rem;
        line-height: 1.55rem;
        margin-left: 0 !important;
    }

    /*    .overlay ul li a:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 43.5%;
            width: 0;
            transform: translateX(-50%);
            height: 2px;
            background: #fff;
            transition: 0.35s;
        }*/

    /*    .overlay ul li a:hover,
        .overlay ul li a:focus,
        .overlay ul li a:active {
            color: #fff;
        }

        .overlay ul li a:hover:after,
        .overlay ul li a:focus:after,
        .overlay ul li a:active:after {
            width: 100%;
        }*/

    .overlay.open li {
        animation: fadeInRight 0.5s ease forwards;
        animation-delay: 0.2s;
    }

    .overlay.open li:nth-of-type(2) {
        animation-delay: 0.25s;
    }

    .overlay.open li:nth-of-type(3) {
        animation-delay: 0.3s;
    }

    .overlay.open li:nth-of-type(4) {
        animation-delay: 0.45s;
    }

    .overlay.open li:nth-of-type(5) {
        animation-delay: 0.5s;
    }

.overlay.open li:nth-of-type(6) {
    animation-delay: 0.55s;
}

.overlay.open li:nth-of-type(7) {
    animation-delay: 0.6s;
}
.overlay.open li:nth-of-type(8) {
    animation-delay: 0.65s;
}
    /* Animation for menu fade in */

    @keyframes fadeInRight {
        0% {
            opacity: 0;
            left: 20%;
        }
        100% {
            opacity: 1;
            left: 0;
        }
    }
