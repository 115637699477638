.detail-container{
    width: 50rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.detail-container img{
    width: 50rem;
    height: 100%;
    object-fit: cover;
    margin: 1rem 0;
}

.detail-container h1{
    font-family: "Fabrikant Medium", serif !important;
    margin-bottom: 0;
    font-size: 4rem;
}

.detail-container h2{
    font-size: 2rem;
    line-height: 3rem;
}

.detail-container .detail-perex{
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-family: "Fabrikant Medium", serif !important;
}

.detail-container .detail-content{
    font-size: 1.2rem;
    line-height: 1.5rem;
    width: 100%;
}

.detail-container .detail-content ul li{
    list-style-type: disc;
    line-height: 2rem;
}

.detail-container .detail-content ol li{
    list-style-type: decimal;
    line-height: 2rem;
}

.ql-editor table {
    border-collapse: collapse;
}

.ql-editor table th,
.ql-editor table td {
    border: 1px solid #ccc;
    padding: 4px 8px;
}

.related-products{
    width: 100%;
    padding-bottom: 2rem;
    margin-top: 2rem;
}

.related-products h2{
    text-align: left;
}

.products-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 2rem 0;
}

.product-card{
   display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}

.product-card > div{
    width: 15rem;
}

.product-card img{
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 0;
}

.product-card h3,
.product-card p{
    margin: .5rem 0;
    text-decoration: none !important;
}

.carousel-item {
    margin-right: 15px; /* Přidá mezery na pravou stranu každé položky */
}

/* Pro poslední položku v karuselu můžete odstranit margin, pokud je to potřeba */
.carousel-item:last-child {
    margin-right: 0;
}

.slick-slide {
    padding: 0 15px; /* Přidá padding na obě strany slide, vytvářející mezery mezi nimi */
}

.slick-prev:before, .slick-next:before{
    display: none;
}

.newsletter-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 2rem 0;
    width: 100%;
}

.newsletter-container form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.newsletter-container form input{
    width: 80%;
    padding: 0.5rem 0.8rem;
    border: 1px solid rgba(32, 87, 165, 0.6);
    color: #2057a5;
    background-color: whitesmoke;
    margin: 0;
    font-size: 1rem;
}

.newsletter-container input:focus-visible{
    outline: none !important;
}

.newsletter-container button{
    font-size: 1rem;
    width: 20%;
    padding: 0.5rem 0.8rem;
    border: 1px solid rgba(32, 87, 165, 0.6);
    color: whitesmoke;
    background-color: #2057a5;
    margin: 0 0 0 1rem;
    cursor: pointer;
}

.newsletter-container button:hover{
    background-color: whitesmoke;
    color: #2057a5;
}

.newsletter-container p{
    margin-top: 0;
    font-family: "Fabrikant Medium", serif !important;
}

@media only screen and (max-width: 930px){
    .detail-container{
        width: 100%;
    }

    .detail-container img{
        width: 100%;
    }

    h1{
        font-size: 3.5rem;
    }

    .detail-container h1{
        margin-top: 5rem !important;
    }

    .product-card > div{
        width: 100%;
    }

    .slick-slide {
        padding: 0;
    }

    .product-card > div{
        width: 20rem;
    }
}

@media only screen and (max-width: 742px) {

    .detail-container h1{
        font-size: 3.5rem;
    }

    .product-card > div{
        width: 90%;
    }

    .newsletter-container form input{
        width: 70%;
    }

    .newsletter-container button{
        width: 30%;
    }

}

@media only screen and (max-width: 560px) {
    .product-card > div{
        width: 100%;
    }
}

@media only screen and (max-width: 420px) {

    .detail-container h1{
        font-size: 2.5rem;
    }

    .newsletter-container form input{
        width: 100%;
    }

    .newsletter-container button{
        width: 100%;
        margin: 1rem 0 0 0;
    }

    .newsletter-container form{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
