.footer-container{
    margin: 1rem;
    /*  padding: 10px 50px;*/
    min-height: 80vh;
    background-attachment: fixed;
}

#footer-container{
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.footer-container h2{
    font-family: "Fabrikant Medium", serif !important;
}

.footer-container .slogan{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: "Freight Medium", serif !important;
    font-size: 2rem;
}

.slogan-mobile {
    display: none;
}

.footer-container .slogan img{
    width: 100px;
    opacity: .5;
}

.footer-container .slogan p{
    font-family: "Freight SemiBold", serif !important;
    font-size: 5rem;
    opacity: .5;
    margin: 5rem 0 10rem 0;
    text-align: center;
    color: #2057a5 !important;

}

.footer-text{
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    /*    color: #2057a5 !important;*/
}

.footer-container .col{
    display: flex;
    flex-direction: column;
}

.footer-container ul {
    padding: 0 !important;
}

.footer-container a {
    margin: 5px 0 !important;
    /*    color: #2057a5 !important;*/
}

.footer-container a {
    line-height: 30px;
    text-decoration: none;
}

.footer-container .icons{
    display: flex;
    flex-direction: column;
}

.footer-copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
}

.footer-copy p:first-child a {
    cursor: auto !important;
}

.footer-copy p:nth-last-child(1) a {
    text-decoration: none !important;
    font-family: "Fabrikant Medium", serif !important;
}

.footer-copy p:nth-last-child(1) a:hover{
    text-decoration: underline !important;
}

.footer-text img {
    width: 25px;
    margin: 0.5rem 1rem 0 0;
}

@media only screen and (max-width: 1024px) {
    .footer-container{
        margin: 0 0 3rem 0;
        padding: 10px 30px;
        height: 80vh;
    }

    .footer-container .slogan-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
    }

    .footer-container .slogan-mobile p {
        font-family: "Freight SemiBold", serif !important;
        font-size: 3rem;
        opacity: .5;
/*        margin: 5rem 0 10rem 0;*/
        text-align: center;
        line-height: 1;
    }

    .footer-container .slogan {
        display: none;
    }

    .footer-container .footer-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
    }

    .footer-container .footer-text .col {
        margin: 1rem 0;
    }

    .footer-container .footer-text .col h2{
        margin-bottom: 0;
    }

    .footer-copy{
        flex-direction: column;
        margin-top: 1rem !important;
        padding-bottom: 2rem !important;
    }

    .footer-copy p{
        margin: .5rem;
    }

    .footer-text img {
        width: 25px;
        margin: 1rem .8rem 0 .8rem;
    }
}

@media only screen and (max-width: 525px) {
    .footer-container .slogan-mobile p{
        margin: 0 0 1rem 0 !important;
    }
}

.text-center{
    text-align: center;
}
