.label-container {
    cursor: pointer;
    margin: 0 1rem;
}

.categories-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 90vw;
}

.input-field{
    width: 50vw;
    max-width: 55vw;
    min-width: 50vw;
    margin: 1rem 0;
}

.input-field *{
    width: 100%;
    margin: 0;
}

.img-preview{
    width: 20rem;
    height: 100%;
    object-fit: cover;
    margin: auto;
}

.input-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}

.ReactQuill{
    width: 60vw;
    max-width: 65vw;
    min-width: 50vw;
    margin: 1rem 0;
}
