@font-face {
    font-family: 'Fabrikant';
    src: url('../fonts/Fabrikant/Fabrikat.otf') format('opentype');
}

@font-face {
    font-family: 'Fabrikant Black';
    src: url('../fonts/Fabrikant/FabrikatBlack.otf') format('opentype');
}

@font-face {
    font-family: 'Fabrikant Bold';
    src: url('../fonts/Fabrikant/FabrikatBold.otf') format('opentype');
}

@font-face {
    font-family: 'Fabrikant Medium';
    src: url('../fonts/Fabrikant/FabrikatMedium.otf') format('opentype');
}

@font-face {
    font-family: 'Freight Medium';
    src: url('../fonts/FreightText/FreightTextMediumItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'Freight Light';
    src: url('../fonts/FreightText/FreightTextProLightItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'Freight SemiBold';
    src: url('../fonts/FreightText/FreightTextProSemiboldItalic.ttf') format('opentype');
}

html{
    scroll-behavior: smooth;
    background-color: #F5F5F5FF;
    min-height: 100vh;
}

*{
    box-sizing: border-box;
    font-family: Fabrikant, 'Fabrikat', serif;
}

.App{
    max-width: 90vw;
    margin: 0 auto;
}

::-moz-selection { /* Code for Firefox */
    color: whitesmoke;
    background: rgba(32, 87, 165, 0.47);
}

::selection {
    color: whitesmoke;
    background: rgba(32, 87, 165, 0.47);
}

a{
    color: #111111;
}

body {
    font-family: Fabrikant, 'Fabrikant', serif;
    color: #111;
    transition: 0.3s ease-out;
    overflow-x: hidden;
    min-height: 100vh;
    max-width: 100vw;
    width: 100%;
    overscroll-behavior: none;
}

h1{
    font-family: "Freight Medium", serif !important;
    font-size: 5rem;
    text-align: center;
    opacity: .8;
    margin-top: 10rem;
}

@media only screen and (max-width: 525px) {
    h1 {
        margin-top: 5rem;
        text-align: center;
        margin-bottom: 0;
    }
}

h2{
    font-family: 'Fabrikant Medium', serif !important;
    font-size: 1.3rem;
    color: #2057a5;
}

.main-container{
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.submit-btn{
    cursor: pointer;
}

form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

form input{
    margin: .5rem;
}

textarea{
    padding: .5rem;
    line-height: 1.2rem;
}

.spinner-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25rem 0;
}

.spinner {
    --uib-color: #2057a5;
    --uib-speed: 2s;
    position: relative;
    height: 45px;
    width: 45px;
}

.spinner::before,
.spinner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    width: 100%;
    background-color: #2057a5;
    animation: pulse var(--uib-speed) linear infinite;
    transform: scale(0);
    transition: background-color 0.3s ease;
}

.spinner::after {
    animation-delay: calc(var(--uib-speed) / -2);
}

@keyframes pulse {
    0 % {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
